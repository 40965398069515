<template>
  <div class="page">
    <Navbar title="个人中心" type="SMP" />
    <van-row class="top">
      <van-col span="6"><van-icon name="user-circle-o" size="50px" /></van-col>
      <van-col span="10">
        <van-row>
          <van-col span="24" class="name"
            >{{ user.name }} <van-icon name="edit"
          /></van-col>
        </van-row>
        <van-row>
          <van-col span="24" class="grade"
            ><van-tag :color="COLOR_M">普通会员</van-tag></van-col
          >
        </van-row>
      </van-col>
      <van-col span="8" class="task"
        ><van-button
          icon="plus"
          :color="COLOR_S1"
          size="mini"
          @click="signIn"
          plain
          >立即签到</van-button
        ></van-col
      >
    </van-row>
    <div class="wallet">
      <van-row>
        <van-col span="5" class="icon"
          ><van-icon name="balance-pay" size="40px"
        /></van-col>
        <van-col span="10" class="amount">￥{{ wallet.coinBalance }}元</van-col>
        <van-col span="9" class="more"
          ><van-button
            plain
            :color="COLOR_S1"
            icon="balance-list-o"
            size="mini"
            @click="walletRecord"
            >明细</van-button
          ><van-button
            plain
            :color="COLOR_S2"
            icon="cash-back-record"
            size="mini"
            @click="walletExtract"
            >提现</van-button
          ></van-col
        >
      </van-row>
    </div>
    <div class="advice">
      <van-image
        class="img"
        :src="require('../../../../assets/images/train/adv-01.jpg')"
      ></van-image>
    </div>
    <div class="navigate">
      <van-cell @click="order()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-order"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 我的预约</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="recmd()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-tuijian"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 我的推荐</van-col>
          </van-row>
        </template></van-cell
      >
    </div>
    <Loading :show="loadingShow" />
    <Tabbar active="own" />
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../../common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import '../../../../assets/icon/font-icon.js'
import { Tag, Icon, Tabs, Tab, Image, Cell } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Tabbar: Tabbar,
    Loading: Loading,
    Share: Share,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Image.name]: Image,
    [Cell.name]: Cell
  },
  data () {
    return {
      loadingShow: false,
      user: {},
      wallet: {},
      orderList: [],
      refereeList: [],
      amount: 0,
      tabActive: 'order',
      orderCode: '2',
      settle: { prior: 0, wait: 0, complete: 0 },
      payOrderCode: '1'
    }
  },
  mounted () {
    document.title = '个人中心-工作'
    this.retrieveUser()
    this.$refs.share.default('main')
  },
  methods: {
    async retrieveWallet () {
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/retrieveWallet')
      if (res.status === '200') {
        this.wallet = res.data
      }
    },
    walletRecord () {
      this.$router.push({ path: '/mde/wallet/record/customer' })
    },
    walletExtract () {
      this.$router.push({ path: '/mde/wallet/extract/customer' })
    },
    async retrieveSeller () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { code: seller }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/retrieveSeller', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.seller = res.data
        this.retrieveWallet()
      }
    },
    async retrieveUser () {
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var pd = { code: user }
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveUser', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.user = res.data
        this.retrieveWallet()
      }
    },
    order () {
      this.$router.push({ path: '/mde/recruit/order' })
    },
    recmd () {
      this.$router.push({ path: '/mde/main/recmd' })
    }
  }
}
</script>
<style lang="less" scoped>
.top {
  padding: 15px 0px 5px 0px;
  .name {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    height: 20px;
    line-height: 20px;
  }
  .grade {
    text-align: left;
    height: 20px;
    line-height: 20px;
  }
  .task {
    height: 50px;
    line-height: 50px;
  }
}
.wallet {
  width: 96%;
  height: 60px;
  border-radius: 5px;
  margin: 5px 0px;
  margin-left: 2%;
  color: #fff;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(#00cc99), to(#00cc99));
  .icon {
    margin-top: 8px;
    text-align: right;
  }
  .amount {
    font-size: 18px;
    font-weight: 600;
    height: 60px;
    line-height: 60px;
    text-align: left;
  }
  .more {
    height: 52px;
    line-height: 52px;
  }
}
.settle {
  padding: 10px 0px;
  background-color: #fff;
  .amount {
    font-size: 16px;
    font-weight: 600;
    padding-top: 10px;
    height: 25px;
    line-height: 25px;
  }
  .text {
    height: 18px;
    line-height: 18px;
    padding-bottom: 10px;
    color: #bababa;
  }
}
.navigate {
  text-align: left;
  .icon {
    margin-top: 3px;
    width: 20px;
    height: 20px;
  }
  .name {
    font-size: 15px;
    height: 25px;
    line-height: 25px;
  }
}
</style>
